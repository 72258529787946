import React, { useState, useEffect } from "react";
import axios from "axios"

import GridOrdem from "./components/Grid-ordem.js";
import AdicionarMateriais from "./components/AdicionarMateriais.js"

import GlobalStyle from "../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { IoMdArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;

  align-items: flex-start;


`;



const Div = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px 0px 0px;


  span{
    padding: 0px  10px;
  }
`;








const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;
const ModalContent = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 

  

`;
const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;



const DivCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  span{
    padding: 0px  10px;
  }
`;

const ButtonSair = styled.button`
  padding: 20px 20px 0px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: transparent;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;

function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050/indicadores/";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/indicadores/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');




  const [onEdit, setOnEdit] = useState(null);





  //Links
  const handleVoltar = () => {

    const url = `${urlLogin}`;
    window.location.href = url;
  };




  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
    console.log('planejamento-entressafra')
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])




  //Abre o modal 'Ver Usuario'
  const [isModalOpentoggleModalMateriais, setIsModalOpentoggleModalMateriais] = useState(false);
  const toggleModalMateriais = () => {
    setIsModalOpentoggleModalMateriais(!isModalOpentoggleModalMateriais);
  };

  const [recebeValorImput, setRecebeValorImput] = useState('');
  const [programacaoFiltreded, setProgramacaoFiltreded] = useState([]);


  // Função para buscar eventos
  const getProgramacao = async () => {
    try {
      const res = await axios.get(`${urlApi}/programacao-entressafra/ordens`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Ordenar os eventos com base no segundo valor (decrescente)
      const sortedEventos = res.data.sort((a, b) => {
        return b[1] - a[1];
      });

      // Mapear os eventos
      const eventosFuncio = sortedEventos.map(item => ({
        id: item[0],
        titulo: item[1],
        ano: item[2],
        ordem: item[3],
        anoOrdem: item[4], // Aqui o valor que será comparado com recebeValorImput
      }));

      // Filtrar os eventos com base no valor de recebeValorImput
      const eventosFiltrados = eventosFuncio.filter(item => item.anoOrdem === recebeValorImput);

      // Atualizar o estado com os eventos filtrados
      setProgramacaoFiltreded(eventosFiltrados);
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  const [servicoFiltreded, setServicoFiltreded] = useState([]);
  // Função para buscar eventos
  const getServico = async () => {
    try {
      const res = await axios.get(`${urlApi}/programacao-entressafra/servicos`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      const sortedEventos = res.data.sort((a, b) => {
        return b[1] - a[1]; // Ordenando pelo segundo valor (decrescente)
      });



      const eventosFuncio = sortedEventos.map(item => ({
        ano: item[0],
        ordem: item[1],
        anoOrdem: item[2],
        id: item[3],
        os: item[4],
        descricao_tarefa: item[5],

      }));
      const eventosFiltrados = eventosFuncio.filter(item => item.anoOrdem === recebeValorImput);

      setServicoFiltreded(eventosFiltrados); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };


  useEffect(() => {
    if (recebeValorImput) {
      getProgramacao(); // Chama a função apenas se recebeValorImput não estiver vazio
      getServico();
    }
  }, [recebeValorImput]);




  return (
    <>


      {isModalOpentoggleModalMateriais && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                toggleModalMateriais();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <AdicionarMateriais onEdit={onEdit} urlApi={urlApi} />
          </ModalContent>
        </ModalContainer>
      )}


      <div >
        <DivLayout>
          <Title>
            <div>
              <ButtonSair onClick={handleVoltar} title="Voltar">
                <IoMdArrowBack />
              </ButtonSair>
            </div>
            <Title2>
              <div>
                <h2>Planejamento Entressafra</h2>
              </div>




            </Title2>

          </Title>


        </DivLayout>

        <Container>
          <DivCenter>

            <GridOrdem setOnEdit={setOnEdit} urlApi={urlApi} toggleModalMateriais={toggleModalMateriais} programacaoFiltreded={programacaoFiltreded} servicoFiltreded={servicoFiltreded} setRecebeValorImput={setRecebeValorImput} />


          </DivCenter>

        </Container>
        <Div>

          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>
      </div>
    </>
  );
}

export default Usuarios;
