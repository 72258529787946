import React, { useState, useEffect } from "react";
import axios from "axios"

import DivExata from './components/Div-exata.js';
import DivCS from './components/Div-cs.js';

import GlobalStyle from "../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import { IoMdArrowBack } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";

const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: 4.0rem;
  background-color: #076633; 
`;

const Title2 = styled.h2`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 55%;
  color: #fff;
  font-size: 28px;

`;

const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const DivCenter = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: 80vh;
  gap: 100px;
`;


const ButtonSair = styled.button`
  padding: 20px 20px 0px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: transparent;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;



const Button = styled.button`
  padding: 10px 30px;
  background-color: #373737;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  &:hover {
    background-color: #45a049;
  }
`;

const Button2 = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Cor do texto */
  background: #076633; 
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    border: 1px solid rgba(7, 102, 51, 0.1); /* Borda verde mais opaca no hover */
  }
`;
function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050/indicadores/";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/indicadores/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');





  //Links
  const handleVoltarDash = () => {

    const url = `${urlLogin}`;
    window.location.href = url;
  };


  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
    console.log('treinamento')
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])





  // Função para buscar Treinamentos
  const [treinamentosEXATA, setTreinamentosEXATA] = useState();
  const getTreinamentos = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/eventos2`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      const sortedEventos = res.data.sort((a, b) => {
        return b[1] - a[1]; // Ordenando pelo segundo valor (decrescente)
      });

      const eventosFuncio = sortedEventos.map(item => ({
        id: item[0],
        eventoId: item[1],
        titulo: item[2],
        data: item[3],
        status: item[4],
        qtdPrevista: item[5],
        qtdParticipantes: item[6],
        faltantes: item[7],
        imprevistos: item[8]
      }));

      setTreinamentosEXATA(eventosFuncio); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  // Get Usuarios da EXATA
  const [usuariosEXATA, setUsuariosEXATA] = useState([]);
  const getUsuariosExata = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/eventos-funcionarios`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const eventosFuncio = res.data.map(item => ({
        id: item[0],
        idEvent: item[1],
        cracha: item[2],
        nome: item[3],
        status: item[7]

      }));

      setUsuariosEXATA(eventosFuncio); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  //Get usuarios da CS
  const [usuariosCS, setUsuariosCS] = useState([]);
  const getUsuariosCS = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/usuarios-cs-departamento`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const eventosFuncio = res.data.map(item => ({
        cracha: item[0],
        cod_departamento: item[1],
        departamento: item[2],

      }));

      setUsuariosCS(eventosFuncio); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  //Get cursos da CS
  const [cursosCS, setCursosCS] = useState([]);
  const getCursosCS = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/cursos-cs`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Verificar se res.data é um array
      if (Array.isArray(res.data)) {
        // Classificar o array pelo valor do item[4] de forma crescente
        const sortedData = res.data.sort((a, b) => {
          return a[6] - b[6]; // Comparar o valor no índice 4
        });

        setCursosCS(sortedData); // Atualizar o estado com o array classificado
      } else {
        console.log("Os dados recebidos não são um array");
      }
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  //Get cargos repitidos da CS
  const [cargosRepitidosCS, setCargosRepitidosCS] = useState([]);
  const getCargosRepitidosCS = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/cargo-agrupados-cs`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (Array.isArray(res.data)) {
        // Classificar o array pelo valor do item[4] de forma crescente
        const sortedData = res.data.sort((a, b) => {
          if (a[3] < b[3]) return -1;
          if (a[3] > b[3]) return 1;
          return 0;
        });

        const convertedData = sortedData.map(item => {
          return [item[0], parseInt(item[1], 10), item[2], item[3]]; // Converte item[1]
        });

        setCargosRepitidosCS(convertedData);
      } else {
        console.log("Os dados recebidos não são um array");
      }


    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  // cursos por cargo
  const [cursosCargoCS, setCursosCargoCS] = useState([]);
  const getCursosCargoCS = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/cursos-por-cargo-cs`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      // Verificar se res.data é um array
      if (Array.isArray(res.data)) {
        // Classificar o array pelo valor do item[4] de forma crescente
        const sortedData = res.data.sort((a, b) => {
          if (a[3] < b[3]) return -1;
          if (a[3] > b[3]) return 1;
          return 0;
        });

        const convertedData = sortedData.map(item => {
          return [item[0], parseInt(item[1], 10), item[2], item[3], item[4], item[5], item[6], item[7], item[8]]; // Converte item[1]
        });
        setCursosCargoCS(convertedData);
      } else {
        console.log("Os dados recebidos não são um array");
      }


    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };


  //Anexos CS
  const [anexoCS, setAnexoCS] = useState([]);
  const getAnexoCS = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/anexo-cs`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (Array.isArray(res.data)) {


        // Classificar o array filtrado pelo valor de item[4] em ordem crescente
        const sortedData = res.data.sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        });

        // Mapear para extrair apenas item[1], item[2], item[4] e item[5]
        const mappedData = sortedData.map(item => [
          item[1], // item[1] convertido para inteiro
          item[2], // item[2] (ex: nome)
          item[4], // item[4] (ex: descrição)
          item[5]  // item[5] (ex: nome do arquivo)
        ]);

        setAnexoCS(mappedData);
      } else {
        console.log("Os dados recebidos não são um array");
      }
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };


  //Merge usuarios
  const [users, setUsers] = useState([]);
  const mergeUsuariosCS_Exata = () => {
    const updatedUsersCS = usuariosCS
      .map(userCS => {
        const matchedUser = usuariosEXATA.find(user => user.cracha == userCS.cracha);
        return {
          ...userCS,
          nome: matchedUser ? matchedUser.nome : 'Nome não encontrado',

        };
      })
      .filter(userCS => userCS.nome !== 'Nome não encontrado') // Filtra os usuários sem nome
      .sort((a, b) => a.cracha - b.cracha); // Ordena de forma decrescente pelo cracha

    setUsers(updatedUsersCS);


  };

  //Merge usuarios
  const [usuariosExata_Treinamento, setUsuariosExata_Treinamento] = useState([]);
  const mergeUsuariosExata_Treinamento = () => {
    const updatedUsersCS = usuariosEXATA
      .map(userExata => {
        const matchedUser = treinamentosEXATA.find(evento => evento.eventoId == userExata.idEvent);
        return {
          ...userExata,
          titulo: matchedUser ? matchedUser.titulo : 'Titulo não encontrado',
          data: matchedUser ? matchedUser.data : 'Data não encontrada',
        };
      })
    //.filter(userCS => userCS.nome !== 'Nome não encontrado') // Filtra os usuários sem nome
    //.sort((a, b) => a.cracha - b.cracha); // Ordena de forma decrescente pelo cracha

    setUsuariosExata_Treinamento(updatedUsersCS);
  };

  const [exata_CS, setExata_CS] = useState([]);
  const mergeUsuariosExata_CS = () => {
    const updatedUsersCS = usuariosEXATA
      .map(userCS => {
        const matchedUser = usuariosCS.find(user => user.cracha == userCS.cracha);
        return {
          ...userCS,
          departamento: matchedUser ? matchedUser.departamento : 'Departamento não encontrado',
          cod_departamento: matchedUser ? matchedUser.cod_departamento : 'Cod não encontrado',
        };
      })
      .filter(userCS => userCS.nome !== 'Nome não encontrado') // Filtra os usuários sem nome
      .sort((a, b) => a.cracha - b.cracha); // Ordena de forma decrescente pelo cracha

    setExata_CS(updatedUsersCS);


  };


  const [mostrarBotoes, setMostrarBotoes] = useState(false);
  const [divAtiva, setDivAtiva] = useState(null);

  // Função para gerar relatórios e exibir botões DivExata e DivCS
  const handleGerar = () => {
    getTreinamentos();
    getUsuariosExata();
    getCursosCS();
    setMostrarBotoes(true);
    setDivAtiva(null); // Garante que nenhuma div seja exibida ao clicar em Gerar
  };

  useEffect(() => {
    // Chama essas funções ao montar o componente, sem depender do estado 'relatorios'
    getUsuariosCS();
    mergeUsuariosCS_Exata();
    mergeUsuariosExata_Treinamento();
    mergeUsuariosExata_CS();
    getCargosRepitidosCS();
    getCursosCargoCS();
    getAnexoCS();
  }, [treinamentosEXATA, usuariosEXATA]); // Executa apenas uma vez ao carregar o componente

  // Função para controlar a troca entre DivExata e DivCS
  const handleMostrarDiv = (div) => {
    setDivAtiva(div);
  };


  // Função para voltar ao menu de Divs
  const handleVoltar = () => {
    setDivAtiva(null); // Volta ao menu de botões DivExata e DivCS
  };




  return (
    <div>
      <DivLayout>
        <Title>
          <Title2>
            <DivTitle>
              {(divAtiva === 'Exata' || divAtiva === 'CS') ? (
                <ButtonSair onClick={handleVoltar} title="Voltar">
                  <IoMdArrowBack />
                </ButtonSair>
              ) : (
                <ButtonSair onClick={handleVoltarDash} title="Voltar">
                  <IoMdArrowBack />
                </ButtonSair>
              )}
              <DivTitle>
                {(divAtiva === 'Exata' || divAtiva === 'CS') ? (
                  <h2>{divAtiva}</h2>
                ) : (
                  <h2>Treinamentos</h2>
                )}

              </DivTitle>
            </DivTitle>

            <Button onClick={handleGerar}>
              <TfiReload /> Gerar Relatório
            </Button>
          </Title2>
        </Title>
      </DivLayout>

      <Container>
        {mostrarBotoes && !divAtiva && (
          <DivCenter>
            {/* Botões para exibir DivExata ou DivCS */}
            <Button2 onClick={() => handleMostrarDiv('Exata')}>
              Exata
            </Button2>
            <Button2 onClick={() => handleMostrarDiv('CS')}>
              CS
            </Button2>
          </DivCenter>
        )}

        {/* Exibir a DivExata ou DivCS selecionada */}
        {divAtiva === 'Exata' && (
          <>
            <DivExata urlApi={urlApi} usuariosExata_Treinamento={usuariosExata_Treinamento} exata_CS={exata_CS} treinamentosEXATA={treinamentosEXATA} users={users} setDivAtiva={setDivAtiva} />
          </>
        )}
        {divAtiva === 'CS' && (
          <>
            <DivCS exata_CS={exata_CS} anexoCS={anexoCS} cursosCS={cursosCS} cursosCargoCS={cursosCargoCS} cargosRepitidosCS={cargosRepitidosCS} setDivAtiva={setDivAtiva} />
          </>
        )}
      </Container>

      <div>
        <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
        <GlobalStyle />
      </div>
    </div>
  );
}

export default Usuarios;
