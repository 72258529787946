import React, { useState, useEffect } from "react";
import axios from 'axios';

import Frame from './components/Frame.js';

import GlobalStyle from "../../../styles/global2.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import { SlSizeFullscreen } from "react-icons/sl";
import { RiArrowGoBackFill } from "react-icons/ri";


const Geral = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    cursor: pointer;
    transition: transform 0.1s;

    
  }
  @media (max-width: 480px) {
    overflow: hidden;
  }
  @media (max-width: 400px) {
    overflow: hidden;
  }
`;

const Div = styled.div`
  width: 100%;
  margin-left: 65px;
`;

const DivLayout = styled.aside`
  width: 60px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.isOpen ? "250px" : "60px")};
  background-color: #076633;
  color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;

  @media (max-width: 480px) {
    display: none; /* Esconde o aside em telas menores */
  }
`;

const CenteredButtons = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; /* Fundo opcional */
    z-index: 1000; /* Certifica-se de que aparece no topo */
    gap: 20px; /* Espaçamento entre os botões */
    overflow: hidden;
  }
`;


const Divbutton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Divbuttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0px 0px 20px 5px;
`;


const ButtonSide2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #076633;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }

`;

const ButtonSide = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #076633;
  font-size: 18px;
  font-weight: bold;  // Adiciona negrito
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 

  @media (max-width: 480px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  width: 250px;
  height: 60px;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Cor do texto */
  background: rgb(7, 102, 51); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    background: rgba(7, 102, 51); /* Fundo verde um pouco mais opaco no hover */
    border: 1px solid rgba(7, 102, 51, 0.1); /* Borda verde mais opaca no hover */
  }
`;




function App() {

  //----------------------------//
  //Ip's da api e do front
  //const urlFront = "http://192.168.100.103:3050/indicadores";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/indicadores/";
  const urlApi = "/api";
  const urlLogin = "/";

  //----------------------------//

  //Token da api
  const token = localStorage.getItem('token');



  //----------------------------//

  //Passa os links para o Frame.js redenrizar
  const [currentUrl, setCurrentUrl] = useState(null);


  //----------------------------//


  //Links
  const handle162 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:162`;
    setCurrentUrl(url);
  };

  const handle175 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:175`;
    setCurrentUrl(url);
  };

  const handle183 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:183`;
    setCurrentUrl(url);
  };

  const handle188 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:188`;
    setCurrentUrl(url);
  };

  const handle197 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:197`;
    setCurrentUrl(url);
  };

  const handle198 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:198`;
    setCurrentUrl(url);
  };
  //----------------------------//
  const handle162m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:162`;
    window.location.href = url;
  };

  const handle175m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:175`;
    window.location.href = url;
  };

  const handle183m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:183`;
    window.location.href = url;
  };

  const handle188m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:188`;
    window.location.href = url;
  };

  const handle197m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:197`;
    window.location.href = url;
  };

  const handle198m = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:198`;
    window.location.href = url;
  };



  //----------------------------//

  //Verifica o token do usuário
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });

    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
    console.log('boletins')
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 60000);


    return () => clearInterval(intervalId);
  }, [])






  //----------------------------//


  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };


  const toggleInicio = () => {
    const url = `${urlLogin}`;
    window.location.href = url;
  };






  const [isMobile, setIsMobile] = useState(false);

  // Detecta mudanças no tamanho da tela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chama uma vez para setar o estado inicial

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Geral>


        <Div>
          <Frame currentUrl={currentUrl} />
        </Div>

        <>
          {!isMobile ? (
            <DivLayout>
              <Divbutton>
                <ButtonSide2 onClick={toggleInicio} title="Tela inicial">
                  <RiArrowGoBackFill />
                </ButtonSide2>
              </Divbutton>

              <ul>
                <ButtonSide onClick={handle162}>162</ButtonSide>
                <ButtonSide onClick={handle175}>175</ButtonSide>
                <ButtonSide onClick={handle183}>183</ButtonSide>
                <ButtonSide onClick={handle188}>188</ButtonSide>
                <ButtonSide onClick={handle197}>197</ButtonSide>
                <ButtonSide onClick={handle198}>198</ButtonSide>
              </ul>
              <Divbuttons>

                <ButtonSide2 onClick={toggleFullscreen} title="Tela cheia">

                  <SlSizeFullscreen />

                </ButtonSide2>

              </Divbuttons>

            </DivLayout>
          ) : (
            <CenteredButtons>
              <ContentWrapper>
                <Button onClick={handle162m}>162</Button>
                <Button onClick={handle175m}>175</Button>
                <Button onClick={handle183m}>183</Button>
                <Button onClick={handle188m}>188</Button>
                <Button onClick={handle197m}>197</Button>
                <Button onClick={handle198m}>198</Button>
                <Button onClick={toggleInicio} title="Tela inicial">
                  <RiArrowGoBackFill />
                </Button>
              </ContentWrapper>

            </CenteredButtons>
          )}
        </>

        <GlobalStyle />
      </Geral>
      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
