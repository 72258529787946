import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { TbArrowsMoveHorizontal } from "react-icons/tb";
import { FaPlus, FaSave, FaInfo } from "react-icons/fa";


//import Swal from "sweetalert2";

import './style.css'
import { useDebouncedPutAnexo } from "./useDebouncedPutAnexo.js";



const FormContainer = styled.form`
  width: auto;
  background-color: #f4f4f5;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #f4f4f5;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
`;

const Div = styled.div`
  width: 95%;
  background-color: #f4f4f5;
  display: flex; 
  padding: 20px 10px 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const DivButtons = styled.div`
  max-width:100%;
  background-color: #f4f4f5;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;



const TextAreaContainer = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: column;
`;

const Hr = styled.div`
  display: flex;
  align-items: center; /* Alinha ao centro verticalmente */
  width: 95%; /* Largura total */
  height: 1px; /* Altura da linha */
  background-color: #ccc;
  justify-content: center;
  margin-left: 2%;
`;

const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;


  select {
    width: 190px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 10px;
    background-color: #f4f4f5;
    font-size: 18px;
    color: #3a3f42;
  }


  .save-button {
    right: 10px; /* Ajuste a posição à direita */
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%);
  }
`;





const InputTitle = styled.textarea`
  min-width: 600px; 
  min-height: 200px;
  font-size: 22px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 20px;
  color: #3a3f42;
  background-color: #f4f4f5;
  overflow: auto;
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
  line-height: 1.5; /* Ajustar o espaçamento entre as linhas */
  word-wrap: break-word; /* Permitir quebra de palavras longas */
`;


const InputDescricao = styled.textarea`
  min-width: 600px; 
  min-height: 200px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 10px 00px 20px;
  color: #3a3f42;
  background-color: #f4f4f5;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;



const Input = styled.input`
  width: 170px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #f4f4f5;
  font-size: 18px;
  color: #3a3f42;
`;



const Label = styled.label`
  color: #3a3f42;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 0px 5px;
  padding: 0px 0px 0px 0px; 


`;

const Button = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: green;
  color: #fff;
  font-size: bold;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;


`;





const A = styled.a`
  color: #3a3f42;
  text-decoration: none;
  padding: 0px 0px 0px 10px; 
  margin: 0px 0px 0px 10px; 
`;



const Tr = styled.tr`
  display: flex; 
  justify-content: space-between;
  text-align: center;
  padding-bottom: 05px;

`;

export const Td = styled.td`
  font-size: 18px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #3a3f42;
  width: 400px;

`;

const Table = styled.table`
  width: 100%;
  background-color: #f4f4f5;
  padding: 5px 20px;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #3a3f42;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;


const TdButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #f4f4f5;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;



const Checkbox = styled.input`
  margin-right: 8px; 
`;

const ButtonNew = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #3a3f42;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  transition: transform 0.3s;
  border: 1px solid #ccc;
  border-radius: 10px;
    &:hover {

    box-shadow: 0 4px 6px gray;
  }

`;

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');


  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
const Form = ({ editarCartao, modalInfTarefa, urlApi, usuarios, checkbox, fetchCheckbox, modalNovaTarefa, modalMover, visualizador, quadro }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();
  useEffect(() => {
    if (editarCartao) {
      const user = ref.current;

      user.id_criador.value = editarCartao.id_criador;
      user.id_responsavel.value = editarCartao.id_responsavel;
      user.titulo_card.value = editarCartao.titulo_card;
      user.descricao_card.value = editarCartao.descricao_card;
      user.data_criacao.value = editarCartao.data_criacao;
      user.meta_conclusao.value = editarCartao.meta_conclusao;
      user.status.value = editarCartao.status;
      user.recorrente.value = editarCartao.recorrente;
      user.id_cocriador.value = editarCartao.id_cocriador;
      setImageUrl(editarCartao.id_card);
      setImageName(editarCartao.image);
    }

  }, [editarCartao]);


  //---------------------------------------------------------------------------//

  const [tituloCard, setTituloCard] = useState(editarCartao.titulo_card);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const [originalTitulo, setOriginalTitulo] = useState(editarCartao.titulo_card); // Mantém o valor original

  const handleTituloChange = async (e) => {
    e.preventDefault();
    const newTitulo = e.target.value;
    setTituloCard(newTitulo);

    // Exibe o botão apenas se o título foi modificado
    setIsSaveButtonVisible(newTitulo !== originalTitulo);
  };

  const handleSave = async () => {
    try {
      setIsSaveButtonVisible(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-title/${editarCartao.id_card}`,
        { titulo_card: tituloCard },
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setOriginalTitulo(tituloCard);  // Atualiza o valor original após salvar
      // Oculta o botão após o salvamento
      toast.success("Titulo salvo com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };
  //---------------------------------------------------------------------------//


  const [descricaoCard, setDescricaoCard] = useState(editarCartao.descricao_card);
  const [isSaveButtonVisibleDescricao, setIsSaveButtonVisibleDescricao] = useState(false);
  const [originalDescricao, setOriginalDescricao] = useState(editarCartao.descricao_card); // Mantém o valor original

  const handleDescricaoChange = async (e) => {
    e.preventDefault();
    const newDescricao = e.target.value;
    setDescricaoCard(newDescricao);

    // Exibe o botão apenas se a descrição foi modificada
    setIsSaveButtonVisibleDescricao(newDescricao !== originalDescricao); // Correção: aqui você precisa setar o estado correto
  };

  const handleSaveDescricao = async () => {
    try {
      setIsSaveButtonVisibleDescricao(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-desc/${editarCartao.id_card}`,
        { descricao_card: descricaoCard }, // Corrigido: enviar a descrição correta
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setOriginalDescricao(descricaoCard);  // Atualiza o valor original após salvar
      toast.success("Descrição salva com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };


  //---------------------------------------------------------------------------//


  const { isFocused: isFocusedAnexo, setIsFocused: setIsFocusedAnexo, fieldValue: anexoCard, handleChange: handleAnexoChange, handleBlur: handleAnexoBlur
  } = useDebouncedPutAnexo(urlApi, editarCartao.id_card, 'image', token, editarCartao);

  //---------------------------------------------------------------------------//

  const [dataCartao, setDataCartao] = useState(editarCartao.meta_conclusao);
  const [isSaveButtonVisibleData, setIsSaveButtonVisibleData] = useState(false);
  const [originalData, setOriginalData] = useState(editarCartao.meta_conclusao); // Mantém o valor original

  const handleEntregaChange = async (e) => {
    e.preventDefault();
    const newTitulo = e.target.value;
    setDataCartao(newTitulo);

    // Exibe o botão apenas se o título foi modificado
    setIsSaveButtonVisibleData(newTitulo !== originalData);
  };


  //---------------------------------------------------------------------------//


  const [valorCard, setValorCard] = useState(editarCartao.valor);
  const [isSaveButtonVisibleValor, setIsSaveButtonVisibleValor] = useState(false);
  const [originalValor, setOriginalValor] = useState(editarCartao.valor); // Mantém o valor original

  const handleValorChange = async (e) => {
    e.preventDefault();
    const newValor = e.target.value;
    setValorCard(newValor);

    // Exibe o botão apenas se a descrição foi modificada
    setIsSaveButtonVisibleValor(newValor !== originalValor); // Correção: aqui você precisa setar o estado correto
  };

  const handleSaveValor = async () => {
    try {
      setIsSaveButtonVisibleValor(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-valor/${editarCartao.id_card}`,
        { valor: valorCard }, // Corrigido: enviar a descrição correta
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setOriginalValor(valorCard);  // Atualiza o valor original após salvar
      toast.success("Valor salvo com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };


  //---------------------------------------------------------------------------//
  //Filtra as tarefas
  const checkFiltrados = checkbox.filter(v => v.id_card === editarCartao.id_card);


  const handleSaveData = async () => {
    // Verifica se o valor de dataCartao é menor do que algum meta_conclusao em checkFiltrados
    const dataCartaoDate = new Date(dataCartao); // Converte a data para comparação

    const isInvalidDate = checkFiltrados.some((check) => {
      const checkMetaConclusaoDate = new Date(check.meta_conclusao);
      return dataCartaoDate < checkMetaConclusaoDate;
    });

    if (isInvalidDate) {
      return toast.error("A data de conclusão não pode ser menor que uma das datas existentes nas tarefas. Por favor, escolha uma data válida.");

    }

    if (recorrente === "Sim" && !dataCartao) {
      return toast.warn("Por favor a data não pode ser vazia, pois é uma tarefa recorrente");
    }

    try {
      setIsSaveButtonVisibleData(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-meta/${editarCartao.id_card}`,
        { meta_conclusao: dataCartao },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalData(dataCartao);  // Atualiza o valor original após salvar
      toast.success("Data salva com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };


  //---------------------------------------------------------------------------//



  const [imageName, setImageName] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);


  useEffect(() => {
    fetch(`${urlApi}/upload-gerenciamento/img/${imageUrl}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.ok) {
          setImageUrl2(response.url);
          console.log(response)
        } else {
          //console.error('Erro ao obter a imagem:', response.statusText);

        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  }, [imageUrl]);


  //---------------------------------------------------------------------------//

  const handleTarefa = () => {
    modalNovaTarefa();
  };

  const handleMover = () => {
    modalMover();
  };

  //---------------------------------------------------------------------------//
  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === editarCartao.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.login : '';
  //Renderiza o login do responsavel
  const usuarioResponsavel = usuarios.find(user => user.id_usuario === editarCartao.id_responsavel);




  const usuariosVisualizadores = visualizador
    .filter(v => v.id_quadro === editarCartao.id_quadro) // Filtra por quadro
    .map(v => usuarios.find(user => user.id_usuario === v.id_usuario)) // Mapeia para usuários
    .filter(Boolean); // Remove valores undefined caso algum id_usuario não seja encontrado





  //---------------------------------------------------------------------------//

  const [responsavelSelecionado, setResponsavelSelecionado] = useState(editarCartao.id_responsavel); // Mantém o ID do responsável selecionado
  const [isSaveButtonVisibleResponsavel, setIsSaveButtonVisibleResponsavel] = useState(false);
  const [originalResponsavel, setOriginalResponsavel] = useState(editarCartao.id_responsavel); // Valor original

  const handleResponsavelChange2 = (e) => {
    const novoResponsavel = parseInt(e.target.value, 10); // Converte o valor do select para número
    setResponsavelSelecionado(novoResponsavel);

    // Exibe o botão apenas se o ID foi modificado
    setIsSaveButtonVisibleResponsavel(novoResponsavel !== originalResponsavel);
  };

  const handleSaveResponsavel = async () => {
    try {
      setIsSaveButtonVisibleResponsavel(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-responsavel/${editarCartao.id_card}`,
        { id_responsavel: responsavelSelecionado }, // Envia o ID correto
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setUsuarioEmail(responsavelSelecionado); // Atualiza o estado de usuarioEmail
      setOriginalResponsavel(responsavelSelecionado); // Atualiza o valor original após salvar
      toast.success("Responsável alterado com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o responsável";
      toast.error(errorMessage);
    }
  };

  //---------------------------------------------------------------------------//

  const [cocriadorSelecionado, setCocriadorSelecionado] = useState(editarCartao.id_cocriador); // Mantém o ID do responsável selecionado
  const [isSaveButtonVisibleCocriador, setIsSaveButtonVisibleCocriador] = useState(false);
  const [originalCocriador, setOriginalCocriador] = useState(editarCartao.id_cocriador); // Valor original

  const handleCocriadorChange2 = (e) => {
    const novoCocriador = parseInt(e.target.value, 10); // Converte o valor do select para número
    setCocriadorSelecionado(novoCocriador);

    // Exibe o botão apenas se o ID foi modificado
    setIsSaveButtonVisibleCocriador(novoCocriador !== originalCocriador);
  };


  const [cocriadorEmail, setCocriadorEmail] = useState();
  const [cocriadorRecebe, setCocriadorRecebe] = useState();
  const [nomeCocriador, setNomeCocriador] = useState();

  const handleSaveCocriador = async () => {
    try {
      setIsSaveButtonVisibleCocriador(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-cocriador/${editarCartao.id_card}`,
        { id_cocriador: cocriadorSelecionado }, // Envia o ID correto
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setCocriadorEmail(cocriadorSelecionado);
      setOriginalCocriador(cocriadorSelecionado); // Atualiza o valor original após salvar
      toast.success("Co-criador alterado com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o responsável";
      toast.error(errorMessage);
    }
  };

  const enviarEmailCocriador = () => {

    axios.get(`${urlApi}/email-gerenciamento/novo-cocriador`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        userTo: cocriadorRecebe, // Agora atualizado corretamente
        criador: loginCriador,
        titulo: tituloQuadroFiltrado,
        nomeUsuario: nomeCocriador, // Agora atualizado corretamente
        titulo_cartao: editarCartao.titulo_card,
      }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
  };




  //extrai o email do usuário selecionado
  useEffect(() => {
    if (cocriadorEmail && (cocriadorEmail !== id)) {
      const usuarioEmail2 = usuarios.find(user => user.id_usuario === cocriadorEmail);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';
      const nomeRecebe2 = usuarioEmail2 ? usuarioEmail2.nome : 'Usuário não encontrado';
      console.log('2')
      setCocriadorRecebe(emailRecebe2);
      setNomeCocriador(nomeRecebe2);
    }
  }, [cocriadorEmail]);

  useEffect(() => {
    if (cocriadorRecebe && nomeCocriador && cocriadorRecebe !== 'Usuário não encontrado') {
      enviarEmailCocriador();
      console.log('3')
    }
  }, [cocriadorRecebe, nomeCocriador]);

  //----------------------------------------------------------------------------//
  const [usuarioEmail, setUsuarioEmail] = useState();
  const [emailRecebe, setEmailRecebe] = useState();
  const [nomeRecebe, setNomeRecebe] = useState();

  //extrai o email do usuário selecionado
  useEffect(() => {
    if (usuarioEmail && (usuarioEmail !== id)) {
      const usuarioEmail2 = usuarios.find(user => user.id_usuario === usuarioEmail);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';
      const nomeRecebe2 = usuarioEmail2 ? usuarioEmail2.nome : 'Usuário não encontrado';

      setEmailRecebe(emailRecebe2);
      setNomeRecebe(nomeRecebe2);
    }
  }, [usuarioEmail]);

  useEffect(() => {
    if (emailRecebe && nomeRecebe && emailRecebe !== 'Usuário não encontrado') {
      enviarEmail();
    }
  }, [emailRecebe, nomeRecebe]);




  //recebe o titulo do quadro e passa opor email
  const tituloQuadro = quadro.find(qua => qua.id_quadro === editarCartao.id_quadro);
  const tituloQuadroFiltrado = tituloQuadro ? tituloQuadro.titulo_quadro : '';


  const enviarEmail = () => {
    axios.get(`${urlApi}/email-gerenciamento/novo-cartao`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        userTo: emailRecebe, // Agora atualizado corretamente
        criador: loginCriador,
        titulo: tituloQuadroFiltrado,
        nomeUsuario: nomeRecebe, // Agora atualizado corretamente
        titulo_cartao: editarCartao.titulo_card,
      }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
  };

  //---------------------------------------------------------------------------//


  const handleCheckboxChange = async (id_check, isChecked) => {
    // Se estiver marcado, desmarcar e enviar null; senão, enviar a data atual
    const dataConclusao = isChecked ? null : getCurrentDateTime();

    const respondido = checkbox.find(user => user.id_check === id_check);
    if (respondido.resposta_check === null) {
      toast.error("Favor responda a tarefa, antes de concluir.");
      return;
    }

    try {
      const response = await axios.put(
        `${urlApi}/gerenciamento-checkbox/confirmar-tarefa/${id_check}`,
        { data_conclusao: dataConclusao },
        { headers: { Authorization: `Bearer ${token}` } }
      );


      fetchCheckbox(); // Atualiza a lista de checkboxes no frontend
    } catch (error) {
      console.error("Erro ao atualizar checkbox:", error);
      toast.error("Erro ao atualizar a tarefa.");
    }
  };

  //Informações da tarefa
  const handleInfTarefa = (check) => {
    modalInfTarefa(check);
  };





  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0]; // Arquivo selecionado

    if (!selectedImage) {
      setMensagem("Nenhum arquivo selecionado.");
      return;
    }

    // Tratamento do nome do arquivo
    const treatedName = selectedImage.name
      .normalize("NFD") // Decompor caracteres acentuados
      .replace(/[\u0300-\u036f]/g, "") // Remover diacríticos



    const formData = new FormData();
    formData.append("image", selectedImage, treatedName); // Adiciona o arquivo com o nome tratado
    setNameArquivo(treatedName); // Atualiza o state com o nome tratado

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image`, formData, { headers });
      setMensagem(response.data.message); // Mensagem do servidor
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Erro: verifique o tipo de arquivo que está sendo anexado";
      setMensagem(errorMessage);
    }
  };




  //---------------------------------------------------------------------------//

  const [status, setStatus] = useState(editarCartao.status);
  const [isSaveButtonVisibleStatus, setIsSaveButtonVisibleStatus] = useState(false);
  const [originalStatus, setOriginalStatus] = useState(editarCartao.status); // Mantém o valor original

  const handleStatusChange = async (e) => {
    e.preventDefault();
    const novoStatus = e.target.value;
    setStatus(novoStatus);

    // Exibe o botão apenas se o status foi modificado
    setIsSaveButtonVisibleStatus(novoStatus !== originalStatus);
  };

  const handleSaveStatus = async () => {
    try {
      setIsSaveButtonVisibleStatus(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/status-cartao/${editarCartao.id_card}`,
        { status: status },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalStatus(status);  // Atualiza o valor original após salvar
      toast.success("Status salvo com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o status";
      toast.error(errorMessage);
    }
  };

  //---------------------------------------------------------------------------//

  const [recorrente, setRecorrente] = useState(editarCartao.recorrente);
  const [isSaveButtonVisibleRecorrente, setIsSaveButtonVisibleRecorrente] = useState(false);
  const [originalRecorrente, setOriginalRecorrente] = useState(editarCartao.recorrente); // Mantém o valor original

  const handleRecorrenteChange = async (e) => {
    e.preventDefault();
    const novoRecorrente = e.target.value;
    setRecorrente(novoRecorrente);

    // Exibe o botão apenas se o status foi modificado
    setIsSaveButtonVisibleRecorrente(novoRecorrente !== originalRecorrente);
  };

  const handleSaveRecorrente = async () => {

    // Verificação específica para recorrente = 'Sim'
    if (recorrente === "Sim" && !dataCartao) {
      return toast.warn("Por favor, selecione uma data para 'Entrega' quando recorrente for 'Sim'.");
    }

    try {
      setIsSaveButtonVisibleRecorrente(false);
      console.log('recorrente', recorrente)
      await axios.put(
        `${urlApi}/gerenciamento-card/recorrente-cartao/${editarCartao.id_card}`,
        { recorrente: recorrente },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalRecorrente(recorrente);  // Atualiza o valor original após salvar
      toast.success("Recorrente salvo com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o recorrente";
      toast.error(errorMessage);
    }
  };

  //---------------------------------------------------------------------------//

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" "); // Pega apenas a parte da data, ignorando a hora
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };


  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >

          <TextAreaContainer >
            <InputTitle
              id="titulo_card"
              type="text"
              value={tituloCard}  // Bind do valor ao estado
              onChange={handleTituloChange}
              disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}
            />

            {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisible) && (
              <ButtonNew type="button" onClick={handleSave}><FaSave color={'gold'} /> Salvar</ButtonNew>
            )}
          </TextAreaContainer>

          <Div>

            <InputArea>
              <Label>Criador:</Label>
              <Input
                name="id_criador"
                value={loginCriador}
                readOnly
                disabled
              />

            </InputArea>


            <InputArea>
              <Label>Co-criador</Label>


              <select
                name="id_cocriador"
                value={cocriadorSelecionado}
                onChange={handleCocriadorChange2}
                disabled={id !== editarCartao.id_criador}
              >
                <option value="">Escolha</option>
                {usuariosVisualizadores.map((user, index) => (
                  <option key={`${user.id_usuario}-${index}`} value={user.id_usuario}>
                    {user.login} - {user.nome}
                  </option>
                ))}

              </select>


            </InputArea>
            {((id === editarCartao.id_criador) && isSaveButtonVisibleCocriador) && (
              <ButtonNew type="button" onClick={handleSaveCocriador}><FaSave color={'gold'} />Salvar</ButtonNew>
            )}


            <InputArea>
              <Label>Responsável</Label>

              {/* Select para trocar o responsável */}
              <select
                name="id_responsavel"
                value={responsavelSelecionado}
                onChange={handleResponsavelChange2}
                disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}
              >
                <option value="">Escolha</option>
                {usuariosVisualizadores.map((user, index) => (
                  <option key={`${user.id_usuario}-${index}`} value={user.id_usuario}>
                    {user.login} - {user.nome}
                  </option>
                ))}

              </select>


            </InputArea>
            {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleResponsavel) && (
              <ButtonNew type="button" onClick={handleSaveResponsavel}><FaSave color={'gold'} />Salvar</ButtonNew>
            )}

          </Div>
          <Div>
            <InputArea>
              <Label>Situação:</Label>
              <select name="status" onChange={handleStatusChange} value={status} disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}>
                <option value="" disabled>Escolha....</option>
                <option value="A Fazer">A Fazer</option>
                <option value="Programado">Programado</option>
                <option value="Em Andamento">Em Andamento</option>
                <option value="Pausado">Pausado</option>
                <option value="Concluído">Concluído</option>
                <option value="Cancelado">Cancelado</option>
              </select>

            </InputArea>
            <div>
              {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleStatus) && (
                <ButtonNew type="button" onClick={handleSaveStatus}><FaSave color={'gold'} />Salvar</ButtonNew>
              )}
            </div>
            <InputArea >
              <Label>Entrega:</Label>

              <Input
                name="meta_conclusao"
                type="date"
                onChange={handleEntregaChange}
                value={dataCartao}
                disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}
              />

            </InputArea>
            <div>
              {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleData) && (
                <ButtonNew type="button" onClick={handleSaveData}><FaSave color={'gold'} />Salvar</ButtonNew>
              )}
            </div>
            <InputArea>
              <Label>Recorrente:</Label>

              <select name="recorrente" onChange={handleRecorrenteChange} value={recorrente} disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}>
                <option value="" disabled>Escolha....</option>
                <option value="Sim">Sim, vencimento mensal</option>
                <option value="Não">Não</option>
              </select>

            </InputArea>
            <div>
              {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleRecorrente) && (
                <ButtonNew type="button" onClick={handleSaveRecorrente} ><FaSave color={'gold'} />Salvar</ButtonNew>
              )}
            </div>
          </Div>
          <Div>
            <InputArea>
              <Label>Valor:</Label>

              <Input
                placeholder="Ex. 1000.00"
                name="valor"
                type="text"
                onChange={handleValorChange}
                value={valorCard}
                disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}
              />

            </InputArea>
            <div>
              {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleValor) && (
                <ButtonNew type="button" onClick={handleSaveValor}>Salvar</ButtonNew>
              )}
            </div>
            <InputArea>
              <Label>Criado:</Label>
              <Input
                name="data_criacao"
                readOnly
                disabled
                value={formatDate(editarCartao.data_criacao)}
              />
            </InputArea>

          </Div>




          <Div>
            <TextAreaContainer>
              <Label>Descrição:</Label>
              <InputDescricao
                value={descricaoCard}
                onChange={handleDescricaoChange}
                id="descricao_card"
                disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}
              />
              {((id === editarCartao.id_criador || id === editarCartao.id_cocriador) && isSaveButtonVisibleDescricao) && (
                <ButtonNew type="button" onClick={handleSaveDescricao}><FaSave color={'gold'} />Salvar</ButtonNew>
              )}

            </TextAreaContainer>
          </Div>



          <>
            <Div>
              <Table>
                <>
                  {checkFiltrados.length > 0 && (

                    <Tbody>
                      {checkFiltrados.map((check, index) => {
                        const usuarioResponsavelTarefa = usuarios.find(user => user.id_usuario === check.id_responsavel_tarefa);
                        const loginResponsavelTarefa = usuarioResponsavelTarefa ? usuarioResponsavelTarefa.login : 'Responsável não encontrado';

                        return (
                          <Tr key={check.id_check}>
                            <Td width="75%" style={{ textAlign: 'left' }}>
                              {(id === editarCartao.id_criador || id === editarCartao.id_cocriador || id === check.id_responsavel_tarefa || id === editarCartao.id_responsavel) && (
                                <Checkbox
                                  type="checkbox"
                                  checked={check.data_conclusao !== null}
                                  onChange={() => handleCheckboxChange(check.id_check, check.data_conclusao !== null)}
                                />
                              )}

                              <span
                                style={{
                                  textDecoration: check.data_conclusao !== null ? 'line-through' : 'none',
                                  textDecorationColor: 'black', // Define a cor do traço
                                  textDecorationStyle: 'solid', // Define o estilo do traço (solid, wavy, dotted, dashed, etc.)
                                  textDecorationThickness: '1px', // Ajusta a espessura do traço
                                }}
                              >
                                {check.descricao_check}
                              </span>


                              <div style={{ marginTop: '5px', color: '#BFBFBF', marginLeft: '50px' }}>
                                Responsável: {loginResponsavelTarefa} {/* Exibe o login do responsável */}
                              </div>


                            </Td>

                            <TdButton width="5%" onClick={() => handleInfTarefa(check)} style={{ textAlign: 'right' }} type='button'>
                              <FaInfo size={18} color="Gold" placeholder="Visualizar ou responder a tarefa" />
                            </TdButton>

                          </Tr>
                        );
                      })}
                    </Tbody>


                  )}
                </>

              </Table>
            </Div>
          </>

          <Hr />

          <Div>
            {imageUrl2 ? (
              <A href={imageUrl2} target="_blank" rel="noopener noreferrer">
                <p>Arquivo: {imageName}</p>
              </A>
            ) : (
              <A>Nenhum arquivo anexado.</A>
            )}

          </Div>

          <form>
            {(id === editarCartao.id_criador || id === editarCartao.id_cocriador) && (  // Corrige a condicional para verificar se o usuário é o criador
              <>
                <Label>Anexo:</Label>
                {message ? <p>{message}</p> : ""}
                <input
                  type="file"
                  name="image"
                  onChange={(event) => {
                    uploadImageOnChange(event);  // Chama a função para salvar o arquivo no servidor
                    handleAnexoChange(event);    // Chama a função para salvar o nome da imagem no banco
                  }}
                  onFocus={() => setIsFocusedAnexo(true)}
                  onBlur={handleAnexoBlur}       // Chama a função de blur para o debounce
                  className={isFocusedAnexo ? 'input-focused' : ''}
                  disabled={id !== editarCartao.id_criador && id !== editarCartao.id_cocriador}  // Desabilita se não for o criador
                />

              </>
            )}
          </form>

        </DivContainer>

        <FormContainer>
          <DivButtons>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              {(id === editarCartao.id_criador || id === editarCartao.id_cocriador || id === editarCartao.id_responsavel) && (
                <Button onClick={handleTarefa} type="button">
                  Criar/Alterar Tarefas
                </Button>
              )}

              {(id === editarCartao.id_criador) && (
                <Button onClick={handleMover} type="button">
                  Mover Cartão
                </Button>
              )}
            </div>

          </DivButtons>
        </FormContainer>
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


