import React, { useState, useEffect } from "react";
import axios from "axios";

import Form from "./components/Form.js";
import NovaSenha from "./components/NovaSenha.js";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import GlobalStyle from "../../styles/global.js";
import logo from "../../styles/imagem/Logomarca-06.png";
import { IoClose } from "react-icons/io5";



const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 460px;
  margin-top: 25%;
  height: auto;
  background-color: #f5f5f5;



  @media (max-width: 1360px) {
    width: 460px;
  }
  @media (max-width: 480px) {
    width: 360px;
  }
  @media (max-width: 400px) {
    width: 360px;
  }

`;

const Title = styled.nav`
  height: 6rem;
  width: 460px;
  background-color: #076633;
  border-radius: 10px 10px 0px 0px;

  @media (max-width: 1360px) {
    height: 6rem;
    width: 460px;
  }
  
  @media (max-width: 480px) {
    width: 360px;
  }
  @media (max-width: 400px) {
    height: 6rem;
    width: 360px;
  }
  
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;
  padding-top: 5%;
  justify-content: space-around; 
  align-items: center;
  
  div {
    display: flex;
    align-items: center;
    padding-top: 10px ;
  }


  @media (max-width: 1360px) {
    font-size: 25px;
  }
   
`;
const ButtonClose = styled.button`
  position: fixed;
  cursor: pointer;
  border: none;
  background-color: #fff;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.5);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh; 
  overflow-y: auto; 
`;


function Login() {

  const token = localStorage.getItem('token');

  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlFront = "http://192.168.100.103:3050/";
  const urlApi = "/api";
  const urlFront = "/";

  const [isModalOpenNovaSenha, setIsModalOpenNovaSenha] = useState(false);
  const modalNovaSenha = () => {
    setIsModalOpenNovaSenha(!isModalOpenNovaSenha);
  };

  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.data) {
        // Token válido, redireciona para a rota desejada
        window.location.href = `${urlFront}`;
      }
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {

      } else {
        console.error("Erro ao obter token:", error);
        // Aqui você pode tratar outros erros, caso necessário
      }
    }
    console.log('login')
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 10000);


    return () => clearInterval(intervalId);
  }, [])

  return (
    <>
      {isModalOpenNovaSenha && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalNovaSenha();

              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <NovaSenha urlApi={urlApi} modalNovaSenha={modalNovaSenha} />
          </ModalContent>
        </ModalContainer>
      )}
      <Container>
        <Title>
          <Title2>
            <div>
              <img src={logo} alt="Logo" />
            </div>
          </Title2>
        </Title>
        <Form urlApi={urlApi} modalNovaSenha={modalNovaSenha} />
      </Container>
      <ToastContainer
        autoClose={1500}
        position={toast.POSITION.BOTTOM_LEFT}
        style={{ width: '400px', fontSize: '22px' }}
      />
      <GlobalStyle />
    </>
  );
}

export default Login;
