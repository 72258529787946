import React, { useState, useEffect } from "react";
import axios from "axios"
import Form from "./components/Form.js";
import Grid from "./components/Grid.js";
import { useUsuariosHook } from '../../../hook/getUsuarios.js';
import { usePaginaHook } from '../../../hook/getPagina.js';
import { useModuloHook } from '../../../hook/getModulo.js';
import { useAcessoHook } from '../../../hook/getAcesso.js';


import GlobalStyle from "../../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../styles/imagem/Logomarca-06.png";
import { toast, ToastContainer } from "react-toastify";
import { ImExit } from "react-icons/im";
import { RiArrowGoBackFill } from "react-icons/ri";

const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;

  img{
    padding-top: 10%;
  }  
`;

const Div = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;

const Filtro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

`;


const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #076633;
  color: #fff;
  font-size: 18px;

  &.toggle-btn {
    position: absolute;
    top: 2;
    left: 0;
    padding-left: 1vw;
  }

  &.button-exit{
    position: absolute;
    top: 2;
    right: 0;
    padding-right: 1vw;
  }

`;



function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');


  const [usuarios, setUsuarios] = useState([]);
  const fetchUsuarios = async () => {
    try {
      const res = await axios.get(`${urlApi}/usuarios`, { headers: { Authorization: `Bearer ${token}` } });
      setUsuarios(res.data);
      fetchAcessos();
    } catch (error) {
      console.error('Erro ao buscar dados de usuarios:', error);
    }
  };

  const [acessos, setAcessos] = useState([]);
  const fetchAcessos = async () => {
    try {
      const res = await axios.get(`${urlApi}/portal/acessos`, { headers: { Authorization: `Bearer ${token}` } });
      setAcessos(res.data);
    } catch (error) {
      console.error('Erro ao buscar dados de usuarios:', error);
    }
  };



  useEffect(() => {
    fetchUsuarios();
    fetchAcessos();
  }, [token]);


  const [onEdit, setOnEdit] = useState(null);






  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}login`;
    window.location.href = url;
  };

  const handleVoltar = () => {
    const url = `${urlFront}`;
    window.location.href = url;
  };

  //Verifica o token do usuário
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
    console.log('usuarios-portal')
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])




  useEffect(() => {
    const fetchAcessos = async () => {
      try {
        // Filtra o usuário pelo ID e pelo módulo "gestao"
        const usuarioFiltrado = acessos.find((usuario) => usuario.id_usuario == id && usuario.modulo === "portal");

        // Verifica se o usuário tem o tipo_acesso permitido
        if (usuarioFiltrado && usuarioFiltrado.tipo_acesso !== "A") {
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Erro ao buscar dados de usuarios:', error);
      }
    };

    fetchAcessos();
  }, [id, token]);

  return (
    <>
      <div >
        <DivLayout>
          <Title>
            <div>
              <ButtonSide1 className="toggle-btn" onClick={handleVoltar}>
                <RiArrowGoBackFill className="gap" />
              </ButtonSide1>
            </div>
            <Title2>
              <div>
                <h2>Cadastro de Usuários</h2>
              </div>

              <div>
                <img src={logo} alt="Logo" />
              </div>

            </Title2>
            <div>
              <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair"}>
                <ImExit />
              </ButtonSide1>
            </div>
          </Title>
        </DivLayout>

        <Container>
          <Filtro>
            <Form onEdit={onEdit} setOnEdit={setOnEdit} urlApi={urlApi} fetchUsuarios={fetchUsuarios} acessos={acessos} />
            <Grid setOnEdit={setOnEdit} urlApi={urlApi} usuarios={usuarios} fetchUsuarios={fetchUsuarios} />
          </Filtro>

        </Container>
        <Div>
          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>
      </div>
    </>
  );
}

export default Usuarios;
